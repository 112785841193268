import React, { useCallback, useEffect, useState } from 'react'
import { Route, Redirect } from 'react-router-dom'
import Auth from '../../utils/Auth'

const PrivateRoute = ({ component: Component, ...rest }: any) => {
	const [isAuthenticated, setIsAuthenticated] = useState(false)
	const [needsPasswordChange, setNeedsPasswordChange] = useState(false)
	const [loaded, setLoaded] = useState(false)

	const checkAuth = useCallback(async () => {
		try {
			const isAuth = await Auth.isAuthenticated()
			const token = await Auth.getToken()

			setNeedsPasswordChange(token?.needsPasswordChange || false)
			setIsAuthenticated(isAuth)
			setLoaded(true)
		} catch (error) {
			setLoaded(true)
			setIsAuthenticated(false)
			console.log(error)
		}
	}, [])

	useEffect(() => {
		checkAuth()
	}, [checkAuth])

	return !loaded ? null : (
		<Route
			{...rest}
			render={props => {
				if (isAuthenticated) {
					if (needsPasswordChange) {
						return <Redirect to='/changePassword' />
					}
					return <Component {...props} />
				} else {
					return (
						<Redirect
							to={{
								search:
									'redirectTo=' +
									encodeURIComponent(
										process.env.PUBLIC_URL + props.location.pathname,
									),
								pathname: '/login',
							}}
						/>
					)
				}
			}}
		/>
	)
}

export default PrivateRoute
