import React from 'react'

import { IconCustom } from '@r3/r3-tooling-design-system'
import './LoadingOverlay.scss'

const LoadingOverlay = () => {
	return (
		<div className='loading-overlay'>
			<IconCustom icon='Loading' className='loading-rotation' />
		</div>
	)
}

export default LoadingOverlay
