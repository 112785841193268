// Packages imports
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import * as serviceWorker from './core/serviceWorker'

// Component imports
import App from './core/App'

ReactDOM.render(
  <Router basename={`${process.env.PUBLIC_URL}`}>
    <App />
  </Router>,
  document.getElementById('root'),
)

serviceWorker.unregister()