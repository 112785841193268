import React, { useState, ChangeEvent, FormEvent } from 'react'
import { useHistory } from 'react-router'
import {
	BottomBar,
	Row,
	Column,
	Form,
	PasswordInput,
	Button,
	IconCustom,
	Pictogram,
	Alert,
} from '@r3/r3-tooling-design-system'
import Auth from '../../utils/Auth'
import { SiteHeader } from '../../components'
import './ChangePassword.scss'
import { AppengAuthError } from 'appeng-auth-js'

const ChangePassword = () => {
	const history = useHistory()
	const [oldPassword, setOldPassword] = useState('')
	const [newPassword, setNewPassword] = useState('')
	const [isFirstError, setIsFirstError] = useState(false)
	const [showSuccessAlert, setShowSuccessAlert] = useState(false)
	const [showErrorAlert, setShowErrorAlert] = useState(false)
	const [confirmPassword, setConfirmPassword] = useState('')
	const [oldPasswordErrorMessage, setOldPasswordErrorMessage] = useState<
		string | null
	>(null)
	const [newPasswordErrorMessage, setNewPasswordErrorMessage] = useState<
		string | null
	>(null)
	const [
		confirmPasswordErrorMessage,
		setConfirmPasswordErrorMessage,
	] = useState<string | null>(null)
	const [validationErrorMessages, setValidationErrorMessages] = useState<Array<
		string
	> | null>(null)
	const [
		passwordDefaultErrorMessages,
		setPasswordDefaultErrorMessages,
	] = useState<Array<string> | null>(null)

	const handleSubmit = async (event: FormEvent) => {
		event.preventDefault()
		setShowErrorAlert(false)

		if (newPassword && confirmPassword && newPassword !== confirmPassword) {
			setNewPasswordErrorMessage('Passwords do not match!')
			setConfirmPasswordErrorMessage('Passwords do not match!')
			return
		}

		try {
			await Auth.changePassword(oldPassword, newPassword, confirmPassword)
			setValidationErrorMessages(null)
			setShowSuccessAlert(true)
			setTimeout(() => {
				setShowSuccessAlert(false)
				history.push('/logout')
			}, 2000)
		} catch (error) {
			if (error instanceof AppengAuthError && error.genericError) {
				const { genericError } = error
				if (genericError.validation) {
					setValidationErrorMessages(
						genericError.validation.objectErrors.map(e => e.message!!),
					)

					if (!isFirstError) {
						setPasswordDefaultErrorMessages(
							genericError.validation.objectErrors.map(e => e.message!!),
						)
						setIsFirstError(true)
					}
				} else {
					setShowErrorAlert(true)
				}
			} else {
				setOldPasswordErrorMessage('Please double check your passwords')
			}
		}
	}

	return (
		<div className='changePasswordPage bg-light-gray'>
			<SiteHeader />

			<div className='changePasswordPage__formContainer'>
				<Form
					onSubmit={handleSubmit}
					title='Change password'
					style={{ width: '100%' }}
				>
					<Row>
						<Column
							sm={validationErrorMessages ? 6 : 12}
							md={validationErrorMessages ? 6 : 12}
							lg={validationErrorMessages ? 6 : 12}
						>
							<PasswordInput
								id='oldPassword'
								required
								label='old password'
								name='oldPassword'
								className='mb-5'
								style={{ marginTop: '60px' }}
								invalid={oldPasswordErrorMessage}
								errorMessage={oldPasswordErrorMessage}
								value={oldPassword}
								onChange={(e: ChangeEvent<HTMLInputElement>) => {
									setOldPasswordErrorMessage(null)
									setOldPassword(e.currentTarget.value)
								}}
							/>

							<PasswordInput
								id='newPassword'
								required
								label='new password'
								className='mb-5'
								invalid={!!newPasswordErrorMessage}
								errorMessage={newPasswordErrorMessage}
								name='newPassword'
								value={newPassword}
								onChange={(e: ChangeEvent<HTMLInputElement>) => {
									setNewPasswordErrorMessage(null)
									setConfirmPasswordErrorMessage(null)
									setNewPassword(e.currentTarget.value)
								}}
							/>

							<PasswordInput
								id='confirmPassword'
								required
								label='confirm new password'
								invalid={!!confirmPasswordErrorMessage}
								errorMessage={confirmPasswordErrorMessage}
								name='confirmPassword'
								value={confirmPassword}
								onChange={(e: ChangeEvent<HTMLInputElement>) => {
									setConfirmPasswordErrorMessage(null)
									setNewPasswordErrorMessage(null)
									setConfirmPassword(e.currentTarget.value)
								}}
							/>

							{showSuccessAlert && (
								<Alert variant='success' className='mt-6'>
									You have successfully changed your password! You will now be
									logged out.
								</Alert>
							)}

							{showErrorAlert && (
								<Alert variant='danger' className='mt-6'>
									Please double check the spelling of your password! Use the eye
									icon on each field to reveal your password.
								</Alert>
							)}
							<div className='flex mt-10'>
								<Button type='submit' variant='primary' className='mr-5'>
									Confirm
								</Button>
								<Button variant='secondary' onClick={() => history.push('/')}>
									Cancel
								</Button>
							</div>
						</Column>
						{validationErrorMessages && (
							<>
								<Column sm={1} md={1} lg={1} />
								<Column sm={5} md={5} lg={5}>
									<div>
										<Pictogram
											icon='EssentialsLock'
											className='flex'
											style={{
												margin: '0 auto',
												marginTop: '-45px',
												marginBottom: '50px',
											}}
										/>
										{passwordDefaultErrorMessages &&
											passwordDefaultErrorMessages.map(item => {
												if (
													validationErrorMessages.some(err =>
														err.includes(item),
													)
												) {
													return (
														<span
															key={item}
															className='flex mb-4 danger'
															style={{ color: 'var(--color-red)' }}
														>
															<IconCustom
																icon='CloseThick'
																className='h-4 mt-1 mr-3'
															/>
															{item}
														</span>
													)
												} else {
													return (
														<span key={item} className='flex mb-4 danger'>
															<IconCustom
																icon='CheckBold'
																className='h-4 mt-1 mr-3'
																style={{ color: 'var(--color-blue)' }}
															/>
															{item}
														</span>
													)
												}
											})}
									</div>
								</Column>
							</>
						)}
					</Row>
				</Form>
			</div>

			<BottomBar copyright='© 2020 R3. All rights reserved.'>
				<a rel='noopener noreferrer' target='_blank' href='https://docs.corda.net/' title='Documentation'>
					Documentation
				</a>
			</BottomBar>
		</div>
	)
}

export default ChangePassword
