import React, { useEffect, useState, ChangeEvent, FormEvent } from 'react'

import {
	Button,
	TextInput,
	PasswordInput,
	Checkbox,
	Form,
	Alert,
} from '@r3/r3-tooling-design-system'
import Auth from '../../utils/Auth'
import { AppengAuthError } from 'appeng-auth-js'
import qs from 'qs'
import { LoadingOverlay } from '../../components'
import './SignIn.scss'

const SignIn = () => {
	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')
	const [isLoading, setLoading] = useState(true)
	const [inactivityLogout, setInactivityLogout] = useState(false)
	const [availableGrantTypes, setAvailableGrantTypes] = useState<string[]>([])
	const [errorMessage, setErrorMessage] = useState<string | null>(null)

	const getRedirectUriFromUrl = () => {
		let param = qs.parse(window.location.search, { ignoreQueryPrefix: true })[
			'redirectTo'
		]
		if (!param) {
			param = process.env.PUBLIC_URL
		}
		return decodeURIComponent(param as string)
	}

	useEffect(() => {
		async function initialize() {
			const grantTypes = await Auth.getAvailableGrantTypes()
			setAvailableGrantTypes(grantTypes)
			let redirectTo = getRedirectUriFromUrl()
			try {
				const state = await Auth.handleLoginPromise()

				if (state) {
					redirectTo = state
				}

				const isAuth = await Auth.isAuthenticated()
				if (isAuth) {
					window.location.assign(redirectTo)
				} else {
					Auth.sessionCreatedPromise.then(() => {
						window.location.assign(`${window.location.protocol}//${window.location.host}/launcher`);
					});
					setLoading(false)
				}
			} catch (e) {
				if(e instanceof AppengAuthError){
					setErrorMessage(e.errorMessage);
				} else {
					setErrorMessage('Failed to complete AzureAD authentication.')
				}
				setTimeout(() => {
					setErrorMessage(null)
				}, 10000)
				setLoading(false);
			}
		}
		initialize()
	}, [])

	const handleSubmit = async (e: FormEvent) => {
		e.preventDefault()

		try {
			await Auth.passwordLogin(username, password, !inactivityLogout)
			window.location.assign(getRedirectUriFromUrl())
		} catch (error) {
			if(error instanceof AppengAuthError){
				setErrorMessage(error.errorMessage);
			} else {
				setErrorMessage('Incorrect username or password.')
			}
			setTimeout(() => {
				setErrorMessage(null)
			}, 10000)
		}
	}

	const handleAzureLogin = async (e: any) => {
		e.preventDefault()
		const redirectTo = getRedirectUriFromUrl()
		await Auth.azureLogin(redirectTo, window.location.href.split('?')[0])
	}

	const handleUsernameChange = (e: ChangeEvent<HTMLInputElement>) => {
		setUsername(e.currentTarget.value)
	}

	const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
		setPassword(e.currentTarget.value)
	}

	function validateForm() {
		return username.length > 0 && password.length > 0
	}

	return (
		<div className='root sign-in__wrapper'>
			{isLoading ? (
				<LoadingOverlay />
			) : (
					<Form onSubmit={handleSubmit} className='login-form'>
						<div className='login-form__header-container'>
							<img
								className='login-form__r3Logo'
								src={require('@r3/r3-tooling-design-system/lib/assets/img/logo--r3.svg')}
								alt='r3-logo'
							/>

							<div>
								<span className='login-form__titleRow'>CORDA ENTERPRISE</span>
								<br />
								<span className='login-form__titleRow'>MANAGEMENT CONSOLE</span>
							</div>
						</div>

						<div className='login-form__heading-text mb-5'>
							<h2 className='login-form__loginTitle'>Log in</h2>
						</div>

						{availableGrantTypes.includes('password') && (
							<>
								<TextInput
									id='username'
									label='username'
									name='username'
									className='mb-3'
									value={username}
									onChange={handleUsernameChange}
								/>

								<PasswordInput
									id='password'
									label='password'
									name='password'
									className='mb-3'
									value={password}
									onChange={handlePasswordChange}
								/>
								{errorMessage && <Alert variant='danger'>{errorMessage}</Alert>}
							</>
						)}

						<div className='login-form__buttonsContainer'>
							{availableGrantTypes.includes('password') && (
								<>
									<Checkbox
										className='rememberme-checkbox'
										id='rememberme-checkbox'
										value={inactivityLogout}
										onChange={(e: any) => setInactivityLogout(e.currentTarget.value)}
									>
										Log out after 10 minutes of inactivity
								</Checkbox>
									<Button
										id='loginbutton'
										variant='primary'
										type='submit'
										size='small'
										disabled={!validateForm()}
									>
										SIGN IN
								</Button>
								</>
							)}

							{availableGrantTypes.includes('azuread') &&
								availableGrantTypes.includes('password') && <p>or</p>}

							{availableGrantTypes.includes('azuread') &&
								!availableGrantTypes.includes('password') && (
									<p className='login-form__info-text'>
										Click below to sign in with Azure Active Directory
									</p>
								)}

							{availableGrantTypes.includes('azuread') && (
								<Button
									id='azurebutton'
									iconLeft='MicrosoftAzure'
									size='small'
									variant='primary'
									onClick={handleAzureLogin}
								>
									Login with Azure AD
								</Button>
							)}
						</div>
					</Form>
				)}
		</div>
	)
}

export default SignIn
