import {
	AccessToken,
	GatewayLoginApplication,
	createConfiguration,
	GrantTypes,
	ApplicationEvents,
} from 'appeng-auth-js'

class Auth {
	private clientPromise: Promise<GatewayLoginApplication> = new Promise(
		async (resolve, reject) => {
			try {
				const config = await createConfiguration({ 
					baseUrl:"", 
					inactivityTimeoutSeconds: 600 
				});
				const app = new GatewayLoginApplication(config)
				app.addEventListener(ApplicationEvents.SESSION_DESTROYED, () => {
					window.location.assign(`${window.location.protocol}//${window.location.host}/launcher/login`)
				});
				resolve(app)
			} catch (e) {
				reject(e)
			}
		},
	)

	sessionCreatedPromise: Promise<void> = new Promise(async (resolve, reject) => {
		const client = await this.clientPromise
		client.addEventListener(ApplicationEvents.SESSION_CREATED, () => {
			resolve()
		});
	});

	async handleLoginPromise(): Promise<string | null> {
		const client = await this.clientPromise
		const result = await client.handleLoginPromise()
		return result?.state || null;
	}

	async logout(redirectTo: string): Promise<void> {
		const client = await this.clientPromise
		await client.logoutAsync({ postLogoutRedirectUri: redirectTo })
	}

	async azureLogin(state: string | undefined, redirect: string): Promise<void> {
		const client = await this.clientPromise
		if (client.availableGrantTypes.indexOf(GrantTypes.AZURE_AD) < 0) {
			throw Error('Password login is not enabled.')
		}

		await client.loginAsync({
			grantType: GrantTypes.AZURE_AD,
			state: state,
			loginRedirectUrl: redirect,
		})
	}

	async passwordLogin(
		username: string,
		password: string,
		persist: boolean = false,
	): Promise<void> {
		const client = await this.clientPromise
		if (client.availableGrantTypes.indexOf(GrantTypes.PASSWORD) < 0) {
			throw Error('Password login is not enabled.')
		}
		await client.loginAsync({
			grantType: GrantTypes.PASSWORD,
			persistentSession: persist,
			username: username,
			password: password,
		})

		const token = await client.tryGetTokenAsync()

		if (token.needsPasswordChange) {
			//TODO: force redirect to password change
			console.log('Needs to change password')
		}
	}

	async changePassword(
		oldPassword: string,
		newPassword1: string,
		newPassword2: string,
	): Promise<void> {
		const client = await this.clientPromise
		await client.changePassword({ oldPassword, newPassword1, newPassword2 })
	}

	async isAuthenticated(): Promise<boolean> {
		return await (await this.clientPromise).isAuthenticatedAsync()
	}

	async getToken(): Promise<AccessToken> {
		const client = await this.clientPromise
		return await client.tryGetTokenAsync()
	}

	async getAvailableGrantTypes(): Promise<GrantTypes[]> {
		return (await this.clientPromise).availableGrantTypes
	}
}

export default new Auth()
