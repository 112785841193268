import React, { useEffect, useState } from 'react'
import {
	Container,
	BottomBar,
	Row,
	Column,
	DashboardItem,
} from '@r3/r3-tooling-design-system'
import { SiteHeader } from '../../components'
import './Launcher.scss'
import { Auth } from '../../utils'

type LauncherApp = {
	friendlyName: string
	path: string
	iconPath: string
}

const Launcher = () => {
	const [apps, setApps] = useState(new Array<LauncherApp>())

	const handleLogOut = async () => {
		window.location.assign(
			`${window.location.protocol}//${window.location.host}${process.env.PUBLIC_URL}/logout`,
		)
	}

	useEffect(() => {
		async function init() {
			const authenticated = await Auth.isAuthenticated()
			if (!authenticated) {
				handleLogOut()
			}

			const token = await Auth.getToken()
			const response = await fetch('/api/v2/launcher/apps', {
				method: 'GET',
				headers: { Authorization: `Bearer ${token}` },
			})
			const apps: Array<LauncherApp> = await response.json()

			setApps(apps)
		}

		init()
	}, [])

	return (
		<div className='launcherPage'>
			<Container fluid>
				<SiteHeader />

				<Container>
					<Row>
						<Column>
							<h4 className='launcherApp__pageHeader mt-10 mb-10'>
								Corda Enterprise Management Console
							</h4>
						</Column>
					</Row>

					<Row>
						{apps.length > 0 &&
							apps.map(item => {
								return (
									<Column cols={2} key={item.path}>
										<a
											href={`${window.location.protocol}//${window.location.host}${item.path}`}
											title={item.friendlyName}
										>
											<DashboardItem image={item.iconPath} withBackground>
												{item.friendlyName}
											</DashboardItem>
										</a>
									</Column>
								)
							})}
					</Row>
				</Container>
			</Container>

			<BottomBar copyright='© 2020 R3. All rights reserved.'>
				<a rel='noopener noreferrer' target='_blank' href='https://docs.corda.net/' title='Documentation'>
					Documentation
				</a>
			</BottomBar>
		</div>
	)
}

export default Launcher
