import React, { Suspense } from 'react'
import { Switch, Route } from 'react-router-dom'
import '@r3/r3-tooling-design-system/lib/index.scss'

// Pages
import { SignIn, NotFound, Launcher, SignOut, ChangePassword } from '../pages'

// Components
import { PrivateRoute, LoadingOverlay } from '../components'

const App = () => {
	return (
		<Suspense fallback={<LoadingOverlay />}>
			<Switch>
				<PrivateRoute path='/' exact component={Launcher} />
				<Route path='/login' component={SignIn} />
				<Route path='/logout' component={SignOut} />
				<Route path='/changePassword' component={ChangePassword} />
				<Route component={NotFound} />
			</Switch>
		</Suspense>
	)
}

export default App
