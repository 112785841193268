import React, { useCallback, useEffect } from 'react'
import { Auth } from '../../utils'

export const SignOut: React.FunctionComponent<any> = () => {
    const logout = useCallback(async () => {
        try {
          await Auth.logout(`${window.location.protocol}//${window.location.host}${process.env.PUBLIC_URL}/login`)
        } catch (error) {
          console.log(error)
        }
      }, [])
    
      useEffect(() => {
        logout()
      })

     return null;
}