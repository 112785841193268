import React from 'react'
import { useHistory, NavLink } from 'react-router-dom'
import {
	TopNavBar,
	IconCustom,
	Dropdown,
	Option,
} from '@r3/r3-tooling-design-system'
import logo from '@r3/r3-tooling-design-system/lib/assets/img/logo--r3.svg'

const SiteHeader = () => {
	const history = useHistory()

	const getUserName = (): string => {
		const session = localStorage.getItem('com.r3.accesstoken')

		if (session) {
			return JSON.parse(session).accessToken.userName
		}

		return ''
	}

	const handleLogOut = async () => {
		window.location.assign(
			`${window.location.protocol}//${window.location.host}${process.env.PUBLIC_URL}/logout`,
		)
	}

	const grantType = (): string => {
		const session = localStorage.getItem('com.r3.accesstoken')
		return session ? JSON.parse(session).grantType : ''
	}

	return (
		<TopNavBar
			logo={logo}
			logoWidth='33px'
			title={
				<div>
					<p className='title__heading'>corda enterprise</p>
					<h6 className='title__subheading'>MANAGEMENT CONSOLE</h6>
				</div>
			}
			right={
				<>
					<Dropdown
						positionX='left'
						positionY='bottom'
						closeOnSelectOption
						trigger={
							<div className='userButton'>
								<span className='userButton__username'>{getUserName()}</span>
								<IconCustom icon='Account' className='h-5' />
							</div>
						}
					>
						{grantType() !== 'azuread' && (
							<Option
								value='passwordChange'
								onClick={() => history.push('/changePassword')}
							>
								Change password
							</Option>
						)}
						<Option value='logOut' onClick={handleLogOut}>
							Log out
						</Option>
					</Dropdown>
					<NavLink to='/' exact>
						<IconCustom icon='Apps' className='h-5' />
					</NavLink>
				</>
			}
		/>
	)
}

export default SiteHeader
